import { SearchT } from 'types/DataGrid';

export const getGroupCategoriesFilter = ({ name }: SearchT) => ({
    ...(name && {
        group_views: {
            name: {
                _iregex: name.replaceAll(' ', '.*'),
            },
        },
    }),
});
