import { PROPERTY_PRICE_SECTIONS, PROPERTY_RECREATE_SLOT_RATES, PROPERTY_RECREATE_SLOTS } from 'constants/propertyNames';

import {
    array, boolean, object, SchemaOf,
    string,
} from 'yup';

export type CreateScheduleFormFields = {
    priceSeasonId: string;
    priceTypeIds: string[];
    recreateSlots: boolean;
    recreateSlotRates: boolean;
};

export const CREATE_SCHEDULE_FORM_DEFAULT_VALUES: CreateScheduleFormFields = {
    priceSeasonId: '',
    priceTypeIds: [],
    recreateSlots: false,
    recreateSlotRates: false,
};

export enum CREATE_SCHEDULE_FORM_FIELD_KEYS {
    priceSeasonId = 'priceSeasonId',
    priceTypeIds = 'priceTypeIds',
    recreateSlots = 'recreateSlots',
    recreateSlotRates = 'recreateSlotRates',
}

type CreateScheduleFormLabelDataT = Record<keyof CreateScheduleFormFields, string>;

export const CREATE_SCHEDULE_FORM_LABEL_DATA: CreateScheduleFormLabelDataT = {
    priceSeasonId: '',
    priceTypeIds: PROPERTY_PRICE_SECTIONS,
    recreateSlots: PROPERTY_RECREATE_SLOTS,
    recreateSlotRates: PROPERTY_RECREATE_SLOT_RATES,
};

export const CREATE_SCHEDULE_FORM_VALIDATION_SCHEMA: SchemaOf<CreateScheduleFormFields> = object({
    priceSeasonId: string().required(),
    priceTypeIds: array().min(1),
    recreateSlotRates: boolean().required(),
    recreateSlots: boolean().required(),
}).test(
    'atLeastOneRecreateIsTrue',
    '',
    ({ recreateSlotRates, recreateSlots }) => !!recreateSlotRates || !!recreateSlots,
);
