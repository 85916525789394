export const MODAL_TITLE_CREATE_MEMBER_CARD = 'Создание членской карты';
export const MODAL_TITLE_UPDATE_MEMBER_CARD = 'Редактирование членской карты';
export const MODAL_TITLE_CREATE_MEMBER_CARD_TYPE = 'Создание вида членской карты';
export const MODAL_TITLE_UPDATE_MEMBER_CARD_TYPE = 'Редактирование вида членской карты';
export const MODAL_TITLE_CREATE_PRICE = 'Заполнение режимов работы кортов для прайса';
export const MODAL_TITLE_UPDATE_PRICE_RATE_CHECK = 'Предварительный просмотр заполнения тарифов';
export const MODAL_TITLE_CREATE_PRICE_SALES_ROUND = 'Создание раунда продаж';
export const MODAL_TITLE_UPDATE_PRICE_RATE = 'Заполнение тарифов для типа услуг';
export const MODAL_TITLE_CREATE_SCHEDULE = 'Настройка параметров генерации слотов и рейтов расписания';
export const MODAL_TITLE_UPDATE_COURT = 'Редактирование корта';
export const MODAL_TITLE_CREATE_COURT = 'Создание корта';
export const MODAL_TITLE_UPDATE_COURT_TYPE = 'Редактирование типа корта';
export const MODAL_TITLE_CREATE_COURT_TYPE = 'Создание типа корта';
export const MODAL_TITLE_UPDATE_COURT_CATEGORY = 'Редактирование категории корта';
export const MODAL_TITLE_CREATE_COURT_CATEGORY = 'Создание категории корта';
export const MODAL_TITLE_UPDATE_PAYMENT_TYPE = 'Редактирование типа оплаты';
export const MODAL_TITLE_CREATE_PAYMENT_TYPE = 'Создание типа оплаты';
export const MODAL_TITLE_UPDATE_ORGANIZATION = 'Редактирование реквизитов организации';
export const MODAL_TITLE_CREATE_ORGANIZATION = 'Добавление реквизитов организации';
export const MODAL_TITLE_CREATE_ORGANIZATION_LOCATION = 'Добавление локации';
export const MODAL_TITLE_UPDATE_ORGANIZATION_LOCATION = 'Редактирование локации';
export const MODAL_TITLE_CREATE_GROUP = 'Создание вида группы';
export const MODAL_TITLE_UPDATE_GROUP = 'Редактирование вида группы';
export const MODAL_TITLE_UPDATE_PRICE = 'Изменение режима работы корта для прайса';
export const MODAL_TITLE_UPDATE_PRICE_MEMBER_CARD = 'Редактирование тарифов членской карты';
export const MODAL_TITLE_UPDATE_COACH_CATEGORY = 'Редактирование категории тренера';
export const MODAL_TITLE_CREATE_COACH_CATEGORY = 'Создание категории тренера';
export const MODAL_TITLE_UPDATE_COACH = 'Редактирование тренера';
export const MODAL_TITLE_CREATE_COACH = 'Создание тренера';
export const MODAL_TITLE_CREATE_ACQUIRING_SETTING = 'Создание настройки эквайринга';
export const MODAL_TITLE_UPDATE_ACQUIRING_SETTING = 'Редактирование настройки эквайринга';
export const MODAL_TITLE_UPDATE_IMPLEMENTATION = 'Документ резервирования';
export const MODAL_TITLE_UPDATE_PAYMENT_DOCUMENT = 'Редактирование платежного документа';
export const MODAL_TITLE_DISPLAY_COLUMNS = 'Управление отображением столбцов';
export const MODAL_TITLE_SELECT_GROUPING = 'Выбрать группировку';
export const MODAL_TITLE_CREATE_TASK_STATUS = 'Создание статуса';
export const MODAL_TITLE_UPDATE_TASK_STATUS = 'Редактирование статуса';
export const MODAL_TITLE_CREATE_TASK_TYPE = 'Создание шаблона';
export const MODAL_TITLE_UPDATE_TASK_TYPE = 'Редактирование шаблона';

export const MODAL_ACTION_ARCHIVE = 'Вы уверены, что хотите заархивировать';
export const MODAL_ACTION_IRREVERSIBLE = 'Это действие нельзя будет отменить.';

export const MODAL_TITLE_CREATE_AGE = 'Создание возрастной группы';
export const MODAL_TITLE_UPDATE_AGE = 'Редактирование возрастной группы';

export const MODAL_TITLE_CREATE_PRT = 'Создание ПРТ';
export const MODAL_TITLE_UPDATE_PRT = 'Редактирование ПРТ';

export const MODAL_TITLE_CREATE_GROUP_CATEGORY = 'Создание категории группы';
export const MODAL_TITLE_UPDATE_GROUP_CATEGORY = 'Редактирование категории группы';

export const MODAL_TITLE_CREATE_SERVICE_APP_DOCUMENT = 'Создание документа';
export const MODAL_TITLE_UPDATE_SERVICE_APP_DOCUMENT = 'Редактирование документа';
