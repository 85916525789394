import {
    DATA_GRID_FILTER_MODEL_INITIAL_STATE,
    DATA_GRID_PAGE_SIZES_EXTEND,
    PRICE_DATA_GRID_INITIAL_STATE,
} from 'constants/dataGrid';

import React, {
    FC, useCallback, useState,
} from 'react';
import { Button, Paper } from '@mui/material';
import {
    GridColumns,
    GridFilterModel, GridRowsProp, GridSelectionModel,
    GridSortModel,
    ruRU,
} from '@mui/x-data-grid-premium';
import { observer } from 'mobx-react';
import { DEFAULT_PRICE_SORT_MODEL } from 'page/Price/domain/priceSortModel';
import { StyledDataGridWithFooter, StyledPriceBox } from 'page/Price/domain/Styled';
import ActionsMenu from 'shared/component/DataGrid/ActionsMenu';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import { NestedTablePricesT } from 'types/NestedTablePrices';

type PropsT = {
    rows: GridRowsProp;
    columns: GridColumns;
    dataGridProps: NestedTablePricesT;
};

const NestedTablePrices: FC<PropsT> = observer((props) => {
    const { rows, columns, dataGridProps } = props;
    const [rowSelectionModel, setRowSelectionModel] = useState<GridSelectionModel>([]);
    const {
        multipleDeleteAction, multipleRefreshAction, columnGroupingModel, columnVisibilityModel,
    } = dataGridProps;
    const {
        pageSize, setPageSize, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid(DEFAULT_PRICE_SORT_MODEL);
    const handleSortModelChange = useCallback((newSortModel: GridSortModel) => {
        setSortModel(newSortModel);
    }, [setSortModel]);

    const handleFilterModelChange = useCallback((newFilterModel: GridFilterModel) => {
        setFilterModel(newFilterModel);
    }, [setFilterModel]);

    const resetAllParams = () => {
        setFilterModel(DATA_GRID_FILTER_MODEL_INITIAL_STATE);
        setSortModel(DEFAULT_PRICE_SORT_MODEL);
    };

    return (
        <>
            <StyledPriceBox>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={ () => {
                        resetAllParams();
                    } }
                >
                    Сбросить фильтры
                </Button>
                <ActionsMenu
                    deleteAction={ multipleDeleteAction }
                    refreshAction={ multipleRefreshAction }
                    disabled={ !rowSelectionModel.length }
                    selectedRows={ rowSelectionModel }
                />
            </StyledPriceBox>
            <Paper>
                <StyledDataGridWithFooter
                    rows={ rows }
                    columns={ columns }
                    sortModel={ sortModel }
                    filterModel={ filterModel }
                    onSortModelChange={ handleSortModelChange }
                    onFilterModelChange={ handleFilterModelChange }
                    autoHeight
                    pagination
                    rowsPerPageOptions={ DATA_GRID_PAGE_SIZES_EXTEND }
                    localeText={ ruRU.components.MuiDataGrid.defaultProps.localeText }
                    initialState={ PRICE_DATA_GRID_INITIAL_STATE }
                    checkboxSelection={ true }
                    selectionModel={ rowSelectionModel }
                    onSelectionModelChange={ (newSelectionModel) => setRowSelectionModel(newSelectionModel) }
                    columnVisibilityModel={ columnVisibilityModel }
                    columnGroupingModel={ columnGroupingModel }
                    experimentalFeatures={ {
                        columnGrouping: true,
                    } }
                    pageSize={ pageSize }
                    onPageSizeChange={ (newPageSize) => setPageSize(newPageSize) }
                />
            </Paper>
        </>
    );
});

export default NestedTablePrices;
