import { PROPERTY_ACTIONS } from 'constants/propertyNames';

import React, { FC, useMemo } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { Chip, IconButton } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { GROUP_VIEW_LABEL_DATA } from 'page/GroupView/components/formData';
import DeleteButton from 'shared/component/Button/DeleteButton';
import { FlexWrapBox } from 'shared/component/DataGrid/domain/Styled';
import useDeleteGroupView from 'shared/hook/groupView/delete/useDeleteGroupView';
import { filterOperators } from 'shared/utils/headerData/filterOperators';
import renderStringCell from 'shared/utils/headerData/renderStringCell';
import { useStore } from 'store';
import { CustomHeaderData, FilterOptionT } from 'types/DataGrid';

const renderCorrectionFactors: FC<GridRenderCellParams> = (props) => {
    const { row: { correctionFactors, id } } = props;

    const renderCorrectionFactorsItem = useMemo(() => {
        return correctionFactors.map((cf: FilterOptionT) => {
            const { label, value } = cf;

            return (
                <Chip
                    label={ `${label} | ${value}` }
                    variant="outlined"
                    color="primary"
                    size="small"
                    key={ `${id}-${label}` }
                />
            );
        });
    }, [correctionFactors, id]);

    return (
        <FlexWrapBox>
            { renderCorrectionFactorsItem }
        </FlexWrapBox>
    );
};

const RenderActionCell: FC<GridRenderCellParams> = (props) => {
    const { row } = props;
    const { onOpen } = useStore('updateGroupViewModal');
    const { groupViewDelete } = useDeleteGroupView();

    const handleOpenUpdate = (id: string) => {
        onOpen(id);
    };

    return (
        <>
            <IconButton onClick={ () => handleOpenUpdate(row.id) }>
                <EditIcon color="primary" />
            </IconButton>
            <DeleteButton id={ row.id } title={ row.title } actionDelete={ groupViewDelete } />
        </>

    );
};

export const GROUP_VIEW_HEADER_DATA: CustomHeaderData[] = [
    {
        headerName: GROUP_VIEW_LABEL_DATA.name,
        field: 'name',
        align: 'center',
        flex: 1,
        headerAlign: 'center',
        type: 'string',
    },
    {
        headerName: GROUP_VIEW_LABEL_DATA.groupConstantPrice,
        field: 'groupConstantPrice',
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
    },
    {
        headerName: GROUP_VIEW_LABEL_DATA.ignoreBusyCoaches,
        field: 'ignoreBusyCoaches',
        align: 'center',
        headerAlign: 'center',
        type: 'boolean',
    },
    {
        headerName: GROUP_VIEW_LABEL_DATA.ageTypeId,
        field: 'ageType',
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        renderCell: (params) => renderStringCell(params.row.ageTypeId),
    },
    {
        headerName: GROUP_VIEW_LABEL_DATA.categoryId,
        field: 'groupCategory',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'singleSelect',
        renderCell: (params) => renderStringCell(params.row.categoryId),
        hasuraFilterFields: ['group_category', 'id'],
        hasuraSortFields: ['group_category', 'name'],
    },
    {
        headerName: GROUP_VIEW_LABEL_DATA.clientsForMainCoach,
        field: 'clientsForMainCoach',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        headerName: GROUP_VIEW_LABEL_DATA.correctionFactors,
        field: 'correctionFactors',
        flex: 1,
        minWidth: 200,
        align: 'center',
        headerAlign: 'center',
        type: 'array',
        filterOperators,
        renderCell: renderCorrectionFactors,
    },
    {
        headerName: GROUP_VIEW_LABEL_DATA.minSize,
        field: 'minSize',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        headerName: GROUP_VIEW_LABEL_DATA.avgSize,
        field: 'avgSize',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        headerName: GROUP_VIEW_LABEL_DATA.maxSize,
        field: 'maxSize',
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        type: 'number',
    },
    {
        field: 'actions',
        headerName: PROPERTY_ACTIONS,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: RenderActionCell,
        minWidth: 100,
        flex: 0.2,
        align: 'center',
        headerAlign: 'center',
    },
];
