import { useLazyQuery } from '@apollo/client';
import { GroupCategoriesDTO } from 'shared/data-hook/groupCategory/fetch/DTO/GroupCategoriesDTO';
import { GroupCategoriesDataT } from 'shared/data-hook/groupCategory/fetch/fetchAll/GroupCategoriesData';
import { getGroupCategoriesFilter } from 'shared/data-hook/groupCategory/filter/getGroupCategoriesFilter';
import decodeDTO from 'shared/data-hook/utils/DTO/decodeDTO';
import { filterData } from 'shared/data-hook/utils/filter/filterData';
import {
    GROUP_CATEGORIES_QUERY,
    GroupCategoriesResponseT,
} from 'shared/graphql/query/groupCategory/GroupCategoryQuery';
import { FetchDataParams, SearchT } from 'types/DataGrid';
import { EMPTY_GROUP_CATEGORIES_RESULT } from 'types/GroupCategory';

const useGroupCategories = (): GroupCategoriesDataT => {
    const [fetch, { data, loading }] = useLazyQuery<GroupCategoriesResponseT>(
        GROUP_CATEGORIES_QUERY,
        {
            fetchPolicy: 'network-only',
        },
    );

    const decodedGroupCategories = decodeDTO(
        GroupCategoriesDTO,
        EMPTY_GROUP_CATEGORIES_RESULT,
        (groupCategories) => groupCategories,
        {
            groupCategories: data?.groupCategories,
            groupCategoriesCount: data?.groupCategoriesCount,
        },
    );

    const fetchGroupCategories = async ({
        limit, offset, orderBy = {}, search = { name: '' }, where = { items: [], linkOperator: 'and' },
    }: FetchDataParams<SearchT> = {}): Promise<void> => {
        const { name } = search;

        try {
            await fetch({
                variables: {
                    limit,
                    offset,
                    orderBy,
                    name: name.replaceAll(' ', '.*'),
                    where: {
                        ...filterData(where),
                        ...getGroupCategoriesFilter(search),
                    },
                },
            });
        } catch (error) {
            // TODO: use Sentry
            console.error((error as Error).message); // eslint-disable-line
            throw error;
        }
    };

    return {
        fetchGroupCategories,
        data: decodedGroupCategories.groupCategories,
        count: decodedGroupCategories.groupCategoriesCount,
        loading,
    };
};

export default useGroupCategories;
