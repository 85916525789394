import { FC } from 'react';
import { observer } from 'mobx-react';
import { GROUP_CATEGORY_HEADER_DATA } from 'page/GroupCategory/domain/HeaderData';
import { prepareGroupHeaderData } from 'page/GroupView/domain/prepareGroupHeaderData';
import DataGrid from 'shared/component/DataGrid';
import { useStore } from 'store';
import { DataGridPropsT } from 'types/DataGrid';

import { ROW_WITHOUT_CATEGORY } from '../domain/rows';

type PropsT = {
    loading: boolean;
} & DataGridPropsT;

const GroupCategoryList: FC<PropsT> = observer((props) => {
    const { groupCategoryList, count } = useStore('groupCategory');
    const { groupViews } = useStore('groupView');
    const { getFilterOption } = useStore('filterOptions');
    const groupCategoryFilterOptions = getFilterOption('groupCategory');
    const columns = prepareGroupHeaderData(groupCategoryFilterOptions);

    return (
        <DataGrid
            rows={ [...groupCategoryList, { ...ROW_WITHOUT_CATEGORY, groupViews }] }
            rowCount={ count }
            columns={ GROUP_CATEGORY_HEADER_DATA }
            detailPanelColumns={ columns }
            { ...props }
        />
    );
});

export default GroupCategoryList;
