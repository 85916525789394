import { PAGE_TITLE_GROUP_VIEW_SETTING } from 'constants/pageTitles';
import { PROPERTY_SEARCH_BY_NAME } from 'constants/propertyNames';
import { DEFAULT_DEBOUNCE_DELAY_IN_MS } from 'constants/time';

import React, {
    ChangeEvent, FC, useEffect, useState,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Button, TextField } from '@mui/material';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react';
import CreateGroupCategoryModal from 'page/GroupCategory/components/CreateGroupCategoryModal';
import GroupCategoryList from 'page/GroupCategory/components/GroupCategoryList';
import UpdateGroupCategoryModal from 'page/GroupCategory/components/UpdateGroupCategoryModal';
import { GROUP_CATEGORY_HEADER_DATA } from 'page/GroupCategory/domain/HeaderData';
import CreateGroupViewModal from 'page/GroupView/components/CreateGroupViewModal';
import UpdateGroupViewModal from 'page/GroupView/components/UpdateGroupViewModal';
import ControlPanel from 'shared/component/ControlPanel';
import useGroupCategories
    from 'shared/data-hook/groupCategory/fetch/fetchAll/useGroupCategories';
import useGroupViews from 'shared/data-hook/groupView/fetch/fetchAll/useGroupViews';
import { useDataGrid } from 'shared/hook/dataGrid/useDataGrid';
import useFetchDataForGrid from 'shared/hook/dataGrid/useFetchDataForGrid';
import usePageTitle from 'shared/hook/usePageTitle';
import { StyledPageWrap } from 'shared/styled/styledPageWrap';
import { useStore } from 'store';
import { SearchT } from 'types/DataGrid';

const List: FC = observer(() => {
    const {
        pageSize, setPageSize, page, setPage, sortModel, setSortModel, filterModel, setFilterModel,
    } = useDataGrid();
    const { setGroupCategories } = useStore('groupCategory');
    const { setGroupViews } = useStore('groupView');
    const { refetchTrigger } = useStore('refetchTrigger');
    const { onOpen: createGroupViewModal } = useStore('createGroupCategoryModal');
    const { onOpen: groupViewCreateModal } = useStore('createGroupViewModal');
    const [searchFilters, setSearchFilters] = useState<SearchT>({ name: '' });

    const {
        data: groupCategoriesData, loading: groupCategoriesLoading, count: rowCountByCategories, fetchGroupCategories,
    } = useGroupCategories();
    const { data: groupViewsWithoutCategoryData, fetchGroupViews: fetchGroupViewsWithoutCategory, loading: groupViewsWithoutCategoryLoading } = useGroupViews();

    useFetchDataForGrid(sortModel, filterModel, page, pageSize, fetchGroupCategories, refetchTrigger, GROUP_CATEGORY_HEADER_DATA, searchFilters);

    usePageTitle(PAGE_TITLE_GROUP_VIEW_SETTING);

    useEffect(() => {
        setGroupCategories(groupCategoriesData, rowCountByCategories);
    }, [groupCategoriesData, groupCategoriesLoading]);

    useEffect(() => {
        setGroupViews(groupViewsWithoutCategoryData);
    }, [groupViewsWithoutCategoryData, groupViewsWithoutCategoryLoading]);

    useEffect(() => {
        fetchGroupViewsWithoutCategory({ search: { without: true, name: searchFilters.name } }).then();
    }, [searchFilters]);

    const handleSetSearchValue = debounce((name: string) => {
        setSearchFilters({ name });
    }, DEFAULT_DEBOUNCE_DELAY_IN_MS);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSetSearchValue(e.target.value);
    };

    return (
        <StyledPageWrap>
            <ControlPanel>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => createGroupViewModal() }
                    startIcon={ <AddIcon /> }
                >
                    Добавить категорию группы
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={ () => groupViewCreateModal() }
                    startIcon={ <AddIcon /> }
                >
                    Добавить вид группы
                </Button>
                <TextField
                    label={ PROPERTY_SEARCH_BY_NAME }
                    size="small"
                    onChange={ handleSearchChange }
                />
            </ControlPanel>
            <GroupCategoryList
                loading={ groupCategoriesLoading }
                pageSize={ pageSize }
                setPageSize={ setPageSize }
                page={ page }
                setPage={ setPage }
                sortModel={ sortModel }
                setSortModel={ setSortModel }
                filterModel={ filterModel }
                setFilterModel={ setFilterModel }
            />
            <CreateGroupViewModal />
            <CreateGroupCategoryModal />
            <UpdateGroupViewModal />
            <UpdateGroupCategoryModal />
        </StyledPageWrap>
    );
});

export default List;
