import {
    ERROR_GROUP_CATEGORY_UPDATE, SUCCESS_GROUP_CATEGORY_UPDATE,
} from 'constants/notificationMessage';

import { GroupCategoryFormFields } from 'page/GroupCategory/components/formData';
import useGroupCategoryUpdate from 'shared/data-hook/groupCategory/update/useGroupCategoryUpdate';
import { useNotification } from 'shared/hook/useNotification';
import { useStore } from 'store';
import { GroupCategoryT } from 'types/GroupCategory';

const useUpdateGroupCategory = () => {
    const { error, loading, groupCategoryUpdate } = useGroupCategoryUpdate();
    const { showError, showSuccess } = useNotification();
    const { toggleRefetchTrigger } = useStore('refetchTrigger');

    const updateGroupCategory = async (input: GroupCategoryFormFields, groupCategoryId: GroupCategoryT['id']) => {
        const response = await groupCategoryUpdate(input, groupCategoryId);

        if (error || !response || !response.updateGroupCategory) {
            showError(ERROR_GROUP_CATEGORY_UPDATE);
        } else {
            showSuccess(SUCCESS_GROUP_CATEGORY_UPDATE);
        }

        toggleRefetchTrigger();
    };

    return {
        updateGroupCategory,
        loading,
    };
};

export default useUpdateGroupCategory;
