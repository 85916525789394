import { TabPanel } from '@mui/lab';
import { Box, styled } from '@mui/material';
import { StyledDataGrid } from 'shared/component/DataGrid/domain/Styled';

export const StyledPricePageWrap = styled(Box)`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 113px);
    padding: 24px;
`;

export const StyledPricePageTabPanel = styled(TabPanel)({
    height: '100%',
    padding: 0,

    '& .data-grid-wrap': {
        height: '100%',
    },
});

export const StyledDataGridWithFooter = styled(StyledDataGrid)({
    '& div:has(.MuiDataGrid-footerContainer)': {
        background: 'white',
        position: 'sticky',
        bottom: 0,
        zIndex: 10,
    },
});

export const StyledPriceBox = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
});
