import { gql } from '@apollo/client';
import { EmployeeT } from 'types/Employee';
import { AggregateT } from 'types/Graphql';

export type EmployeesResponseT = {
    employees: EmployeeT[];
    employeesCount: AggregateT;
};

export const EMPLOYEE_FILTER_OPTIONS_QUERY = gql`
    query EmployeeFilterOptions {
        filterOptions: employee(
            order_by: {
                full_name: asc
            }
            where: {
                type: {
                    _eq: "coach"
                }
            }
        ) {
            value: id,
            label: full_name
        }
    }
`;

export const EMPLOYEES_QUERY = gql`
    query EmployeesQuery(
        $limit: Int,
        $offset: Int,
        $orderBy: employee_order_by! = {},
        $where: employee_bool_exp! = {}
    ) {
        employees: employee(
            limit: $limit
            offset: $offset
            order_by: [$orderBy]
            where: $where,
        ) {
            id
            full_name
        }
        employeesCount: employee_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`;
